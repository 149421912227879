import { SeoEnrichmentContent } from '@/lib/schema/inventory/types';
import { SeoInventoryQueryResult } from '@/lib/staticQueries';
import { Maybe } from '@/types';

export type SearchType = 'SEO' | 'Search';

// corresponing meta tags for the SEO typs are under inventoryPage
export const SEOMetaTags = {
  homePage: {
    // ev.com
    title: 'EV | New & Used Electric Cars for Sale | EV.com',
    description:
      'EV for Sale | Shop new & used electric cars from trusted partners on EV.com. Save money, drive electric, and help the planet. Discover your perfect EV today!',
    h1Tag: `EV for${'\u00A0'}All`,
    h2Tag: 'Shop electric cars from our trusted partners',
    imageAlt: 'ev.com',
    keywords: 'electric cars for sale',
  },
  buildPage: {
    title: 'Build your $MAKE EV | EV.com',
    description:
      'Build your $MAKE EV on EV.com. Customize your $MAKE EV to fit your needs and preferences with our easy-to-use tools.',
    h1Tag: 'Build your $MAKE EV',
    imageAlt: 'build your $MAKE ev',
    keywords: 'build $MAKE ev',
  },
  buildModelPage: {
    title: 'Build your $YEAR $MAKE $MODEL EV | EV.com',
    description:
      'Build your $MAKE EV on EV.com. Customize your $YEAR $MAKE $MODEL EV to fit your needs and preferences with our easy-to-use tools.',
    h1Tag: 'Choose Your $YEAR $MAKE $MODEL EV Trim',
    imageAlt: 'build your $YEAR $MAKE $MODEL ev',
    keywords: 'build $YEAR $MAKE $MODEL ev',
  },
  // ev.com/search
  searchPage: {
    title: 'EV | Electric Cars for Sale Near Me | EV.com',
    description:
      'Discover a variety of electric cars available for sale near you on EV.com. Use our search tool to find the best electric vehicles in your area.',
    h1Tag: 'EV | Electric Cars For Sale Near Me',
    imageAlt: 'ev cars for sale',
    keywords: 'electric cars for sale near me',
  },
  // seo search pages
  // ev.com/{new|old}-cars/{year?}/{make?}/{model?}/{location?}
  seoPage: {
    title: '$CONDITION $YEAR $MAKE $MODEL For Sale $LOCATION | EV.com',
    description:
      'Discover a wide range of $CONDITION $YEAR $MAKE $MODEL for sale $LOCATION on EV.com. Find your perfect $CONDITION $YEAR $MAKE $MODEL from top brands, compare prices, read reviews, and get the best deals today!',
    h1Tag: '$CONDITION $YEAR $MAKE $MODEL For Sale $LOCATION',
    h2Tag: 'Shop EVs from our trusted partners',
    imageAlt: '$CONDITION $YEAR $MAKE $MODEL for sale $LOCATION',
    keywords: '$CONDITION $YEAR $MAKE $MODEL for sale $LOCATION',
  },
  comparePage: {
    title: 'Compare EV | Electric Vehicle | EV.com',
    description:
      'Effortlessly compare electric vehicles on EV.com to find the perfect match for your driving needs. Explore specs, range, and pricing today.',
    imageAlt: 'compare evs',
    h1Tag: 'Compare Electric Vehicles',
    keywords: 'compare electric vehicles',
  },
  blogPage: {
    title: 'EV News | Electric Cars Buying & Selling News | EV.com',
    description:
      'Stay ahead with the latest EV News on buying & selling electric cars at EV.com. Get insights, trends, and updates to make informed decisions.',
    h1Tag: 'EV News',
    keywords: 'ev news',
  },
  partnerPage: {
    title: 'Partner with EV.com | EV.com',
    description:
      "Let's accelerate the transition towards zero-emission mobility together. Sell more EVs when you partner with us.",
    h1Tag: 'Partner with EV.com',
    keywords: 'partner with ev.com',
  },
  errorpage: {
    title:
      'Page Not Found | Shop new & used electric cars from trusted partners on EV.com.',
    description:
      'EV for Sale | Shop new & used electric cars from trusted partners on EV.com. Save money, drive electric, and help the planet. Discover your perfect EV today!',
    h1Tag: `Sorry we couldn't find the page you were looking for.`,
    h2Tag: 'To find your way to your next EV, try one of the option below.',
    keywords: 'page not found',
  },
  faqPage: {
    title:
      'Frequently Asked Questions | Shop new & used electric cars from trusted partners on EV.com.',
    description:
      'EV for Sale | Shop new & used electric cars from trusted partners on EV.com. Save money, drive electric, and help the planet. Discover your perfect EV today!',
    h1Tag: 'Frequently Asked Questions',
    h2Tag: 'Everything you need to know about EV.com',
    keywords: 'faq',
  },
  dealerPage: {
    title: '$DEALER electric cars for sale | EV.com',
    description:
      'Discover $DEALER electric cars for sale on EV.com. Get the best deals and newest models from your local dealer.',
    h1Tag: '$DEALER Electric Cars for Sale',
    keywords: 'electric cars',
  },
  loginPage: {
    title: 'Login | EV.com',
    description:
      'Login to your EV.com account to access personalized features and manage your electric vehicle preferences.',
    h1Tag: 'Login to your account',
    keywords: 'login',
  },
  accountSettings: {
    title: 'My EVs | EV.com',
    description:
      'Manage your account on EV.com. Customize your experience and stay updated on the latest electric vehicle news.',
    h1Tag: 'My EVs',
    keywords: 'settings account',
  },
  generalModelSeoTemplate: {
    title:
      'Explore All Electric Vehicle Makes & Models | EV.com - Your Ultimate EV Resource',
    description:
      'Discover all electric vehicle makes and models on EV.com. Get the latest updates, reviews, and news to help you choose the perfect EV for your lifestyle and needs.',
    h1Tag: '',
    keywords: '',
  },
};

export type SeoTagType = keyof typeof SEOMetaTags;
export interface SeoTagsParserProps {
  tagType: SeoTagType;
  make?: string;
  model?: string;
  year?: string;
  locationTitle?: string;
  condition?: 'New' | 'Used';
}

export function getSeoTagParser({
  tagType,
  make,
  model,
  year,
  locationTitle,
  condition,
}: SeoTagsParserProps) {
  const makeString = make ?? '';
  const modelString = model ?? '';
  const yearString = year ?? '';
  const locationString = locationTitle ? `In ${locationTitle}` : 'Near Me';
  const conditionString = condition ? `${condition} ` : '';

  const seoMetaObjH1 = SEOMetaTags[tagType].h1Tag
    .replaceAll('$CONDITION', conditionString)
    .replaceAll('$LOCATION', locationString)
    .replaceAll('$MAKE', makeString)
    .replaceAll('$MODEL', modelString)
    .replaceAll('$YEAR', yearString)
    .replace(/\s+/g, ' ')
    .trim();
  const seoMetaObjTitle = SEOMetaTags[tagType].title
    .replaceAll('$CONDITION', conditionString)
    .replaceAll('$LOCATION', locationString)
    .replaceAll('$MAKE', makeString)
    .replaceAll('$MODEL', modelString)
    .replaceAll('$YEAR', yearString)
    .replace(/\s+/g, ' ')
    .trim();
  const seoMetaObjDescription = SEOMetaTags[tagType].description
    .replaceAll('$CONDITION', conditionString)
    .replaceAll('$LOCATION', locationString)
    .replaceAll('$MAKE', makeString)
    .replaceAll('$MODEL', modelString)
    .replaceAll('$YEAR', yearString)
    .replace(/\s+/g, ' ')
    .trim();
  const seoMetaKeywords = SEOMetaTags[tagType].keywords
    .replaceAll('$CONDITION', conditionString)
    .replaceAll('$LOCATION', locationString)
    .replaceAll('$MAKE', makeString)
    .replaceAll('$MODEL', modelString)
    .replaceAll('$YEAR', yearString)
    .replace(/\s+/g, ' ')
    .trim()
    .toLowerCase();

  return {
    seoMetaObjTitle,
    seoMetaObjDescription,
    seoMetaObjH1,
    seoMetaKeywords,
  };
}

export const getSeoEnrichmentContent = (
  searchResults: SeoInventoryQueryResult
): Maybe<SeoEnrichmentContent> => {
  const {
    make,
    model,
    year,
    location: locationInfo,
    condition,
  } = searchResults;

  const location = locationInfo?.title;
  const conditionText = condition ? `${condition} ` : '';

  // Define common placeholder tags
  const placeholders = {
    totalCars: '{{totalCars}}',
    priceRange: '{{priceRange}}',
    popularModels: '{{popularModelsList}}',
    make: '{{make}}',
    model: '{{model}}',
    year: '{{year}}',
    location: '{{location}}',
  };

  // Check scenarios in order of specificity
  // 1. Model Year + City Level (most specific)
  if (make && model && year && location) {
    return {
      title: `${year} ${model} for Sale in ${location} - New & Used EV Listings`,
      content: `The ${year} ${model} is now available in ${location}, offering the latest features and innovations. 
      
Current ${year} ${model} Listings in ${location}:
We have ${placeholders.totalCars} ${year} ${model} EVs for sale with prices ranging from ${placeholders.priceRange}.

Shop now and find the perfect ${year} ${model} in ${location}!`,
    };
  }

  // 2. Make + Model + location Level
  if (make && model && location && !year) {
    return {
      title: `Find ${make} ${model} for Sale in ${location}`,
      content: `If you're looking for a ${make} ${model} in ${location}, EV.com is the best place to start your search. Whether you're interested in a new [Make] [Model] or a certified used version, we have a wide selection to choose from.\n
      Available ${make} ${model} Listings in ${location}\n
      There are ${placeholders.totalCars} of ${make} ${model} for sale in ${location} with prices ranging from ${placeholders.priceRange}.\n
      Find your perfect ${make} ${model} in ${location} today on EV.com!`,
    };
  }

  // 3. Model + location Level
  if (model && location && !make && !year) {
    return {
      title: `${model} for Sale in ${location} - Find the Best Deals`,
      content: `Searching for a ${model} in ${location}? We offer ${placeholders.totalCars} listings:
• Prices from ${placeholders.priceRange}
• Certified pre-owned options
• Verified local dealers

Start exploring ${model} listings in ${location} now!`,
    };
  }

  // 4. Model Year Page
  if (make && model && year && !location) {
    return {
      title: `Buy ${year} ${model} - New & Used ${year} ${model} for Sale`,
      content: ` At EV.com, we offer a wide selection of new, used, and certified pre-owned ${year} ${model} vehicles to help you find the best deal.\n
${model} for Sale on EV.com
There are ${placeholders.totalCars} ${model} listings available with prices ranging from ${placeholders.priceRange}. Choose from different trims, battery options, and features to find the perfect fit for your needs.\n
Explore the best ${year} ${model} deals today!`,
    };
  }

  // 5. Model Page
  if (make && model && !year && !location) {
    return {
      title: `Buy New & Used ${model} - Best Deals on ${model} EVs`,
      content: ` At EV.com, we offer a wide selection of new, used, and certified pre-owned ${model} vehicles to help you find the best deal.\n
${model} for Sale on EV.com
There are currently ${placeholders.totalCars} ${model} listings available with prices ranging from ${placeholders.priceRange}.\n
Whether you're looking for a budget-friendly used ${model} or a brand-new version with the latest upgrades, EV.com has you covered.\n
Why buy a ${model} from EV.com?\n
• Verified dealers\n• Certified pre-owned\n• Transparent pricing\n
Browse all ${model} listings today and drive away in your dream EV!`,
    };
  }

  // 6. Make + location Level
  if (make && location && !model && !year) {
    return {
      title: `${make} for Sale in ${location} - Find the Best ${make} EV Deals`,
      content: `Looking for a ${make} in ${location}? EV.com is your go-to market place for buying new and used ${make} electric vehicles in ${location}. Whether you're searching for a brand-new ${make}, a certified pre-owned model, or a budget-friendly used ${make}, we have you covered.\n
      ${make} Cars Available in ${location}
      Currently, there are ${placeholders.totalCars} ${make} EVs listed for sale in ${location}, with prices ranging from ${placeholders.priceRange}.\n
      Popular ${make} Models in ${location}\n
      ${placeholders.popularModels}\n
      Why Buy a ${make} in ${location}?\n
      • Verified listings from trusted dealers \n• Certified pre-owned Tesla options available \n• Transparent pricing and detailed vehicle information\n• Easy comparison of new and used Tesla models 
      Find the perfect ${make} for sale in ${location} today on EV.com and make the switch to an electric future!
      `,
    };
  }

  // 7. Manufacturer Page
  if (make && !model && !year && !location) {
    return {
      title: `${make} Cars for Sale - Explore ${conditionText}${make} EVs`,
      content: `Currently, there are ${placeholders.totalCars} ${make} vehicles available for sale on EV.com, with prices ranging from ${placeholders.priceRange}. These vehicles are uploaded by our verified dealers, ensuring a reliable and secure buying experience.\n
Popular ${make} Models on EV.com:

${placeholders.popularModels}\n
Browse our ${make} EV listings and find the perfect electric vehicle for your needs today! `,
    };
  }

  // 8. Location-Level Page
  if (location && !make && !model && !year) {
    return {
      title: `Buy Electric Cars in ${location} - New & Used EVs`,
      content: `Looking for electric cars in ${location}? EV.com makes it easy to find the best deals on new and used EVs in your city. With thousands of listings from verified dealers, you can browse and compare options at competitive prices.\n
      Used Electric Cars in ${location}\n
      Currently, there are ${placeholders.totalCars} electric vehicles available in ${location}, with prices ranging from ${placeholders.priceRange}. Our listings include a variety of certified pre-owned options that are pre-inspected by automobile experts for added peace of mind.\n
      Find your next EV in ${location} today!`,
    };
  }

  return null;
};
export interface MakeModelLevelSEOContentProps {
  year?: string;
  make?: string;
  model?: string;
  price?: string;
}
export const getMakeModelLevelSEOContent = (
  content: MakeModelLevelSEOContentProps
) => {
  const { year, make, model, price } = content;
  const currentYear = new Date().getFullYear();
  if (make && model && year && price) {
    const condition = currentYear === Number(year) ? 'New' : 'Used';
    return {
      title: `${condition} ${year} ${make} ${model} | Price starts from ${price} | ${
        process.env.NEXT_PUBLIC_DOMAIN ?? 'EV.com'
      }`,
      description: `Find your perfect ${year} ${make} ${model} at EV.com . Price starts from ${price}. Compare deals & drive electric today! `,
    };
  }
  if (make && !model && !year && price) {
    return {
      title: `New & Used ${make} EV for Sale | Price starts from ${price} | ${
        process.env.NEXT_PUBLIC_DOMAIN ?? 'EV.com'
      }`,
      description: `Find your perfect ${make} at EV.com . Price starts from ${price}. Compare deals & drive electric today! `,
    };
  }
  if (make && !model && year && price) {
    const condition = currentYear === Number(year) ? 'New' : 'Used';
    return {
      title: `${condition} ${year} ${make} EV for Sale | Price starts from ${price} | ${
        process.env.NEXT_PUBLIC_DOMAIN ?? 'EV.com'
      }`,
      description: `Find your perfect ${year} ${make} at EV.com . Price starts from ${price}. Compare deals & drive electric today! `,
    };
  }
  return null;
};
