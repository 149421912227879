import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { AnalyticsEvent } from '@/lib/handleActionTracking';
import { SVGComponent } from '@/types';
import { signInWithRedirect } from 'aws-amplify/auth';
import { FC } from 'react';
import {
  AppleIcon,
  FacebookIcon,
  GoogleIcon,
  MicrosoftIcon,
} from '../../../icons';

export type IdentityProvider = 'facebook' | 'google' | 'apple' | 'microsoft';

const ButtonConfigMap: Record<
  IdentityProvider,
  {
    name: string;
    signIn: () => Promise<void>;
    icon: SVGComponent;
    analytics: Record<
      | 'login'
      | 'signup'
      | 'loginSuccess'
      | 'loginFail'
      | 'signupSuccess'
      | 'signupFail',
      AnalyticsEvent
    >;
  }
> = {
  google: {
    icon: GoogleIcon,
    analytics: {
      login: GOOGLE_TRACK_INFO.googleLoginButton,
      signup: GOOGLE_TRACK_INFO.googleSignUpButton,
      loginSuccess: GOOGLE_TRACK_INFO.googleLoginSuccess,
      loginFail: GOOGLE_TRACK_INFO.googleLoginFail,
      signupSuccess: GOOGLE_TRACK_INFO.googleSignupSuccess,
      signupFail: GOOGLE_TRACK_INFO.googleSignupFail,
    },
    signIn: async () => {
      await signInWithRedirect({ provider: 'Google' });
    },
    name: 'Google',
  },
  facebook: {
    icon: FacebookIcon,
    analytics: {
      login: GOOGLE_TRACK_INFO.facebookLoginButton,
      signup: GOOGLE_TRACK_INFO.facebookSignUpButton,
      loginSuccess: GOOGLE_TRACK_INFO.facebookLoginSuccess,
      loginFail: GOOGLE_TRACK_INFO.facebookLoginFail,
      signupSuccess: GOOGLE_TRACK_INFO.facebookSignupSuccess,
      signupFail: GOOGLE_TRACK_INFO.facebookSignupFail,
    },
    signIn: async () => {
      await signInWithRedirect({ provider: 'Facebook' });
    },
    name: 'Facebook',
  },
  apple: {
    icon: AppleIcon,
    analytics: {
      login: GOOGLE_TRACK_INFO.appleLoginButton,
      signup: GOOGLE_TRACK_INFO.appleSignUpButton,
      loginSuccess: GOOGLE_TRACK_INFO.appleLoginSuccess,
      loginFail: GOOGLE_TRACK_INFO.appleLoginFail,
      signupSuccess: GOOGLE_TRACK_INFO.appleSignupSuccess,
      signupFail: GOOGLE_TRACK_INFO.appleSignupFail,
    },
    signIn: async () => {
      await signInWithRedirect({ provider: 'Apple' });
    },
    name: 'Apple',
  },
  microsoft: {
    icon: MicrosoftIcon,
    analytics: {
      login: GOOGLE_TRACK_INFO.microsoftPersonalLoginButton,
      signup: GOOGLE_TRACK_INFO.microsoftPersonalSignUpButton,
      loginSuccess: GOOGLE_TRACK_INFO.microsoftLoginSuccess,
      loginFail: GOOGLE_TRACK_INFO.microsoftLoginFail,
      signupSuccess: GOOGLE_TRACK_INFO.microsoftSignupSuccess,
      signupFail: GOOGLE_TRACK_INFO.microsoftSignupFail,
    },
    signIn: async () => {
      await signInWithRedirect({ provider: { custom: 'Microsoft' } });
    },
    name: 'Microsoft',
  },
};

export interface SocialFederationButtonProps {
  identityProvider: IdentityProvider;
  actionType: 'login' | 'signup';
  sendAnalytics?: boolean;
  preHook?: () => void;
}

export const SocialFederationButton: FC<SocialFederationButtonProps> = ({
  identityProvider,
  actionType,
  sendAnalytics = true,
  preHook,
}) => {
  const {
    name,
    icon: Icon,
    signIn,
    analytics,
  } = ButtonConfigMap[identityProvider];
  const { handleActionTracking } = useAnalytics();

  const content = `Continue with ${name}`;

  return (
    <Button
      icon={{
        icon: <Icon className="size-xl" />,
        position: 'left',
      }}
      aria-label={content}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        preHook?.();
        signIn()
          .then(() => {
            const analyticsEvent =
              actionType === 'login'
                ? analytics.loginSuccess
                : analytics.signupSuccess;
            handleActionTracking(analyticsEvent);
          })
          .catch(() => {
            const analyticsEvent =
              actionType === 'login'
                ? analytics.loginFail
                : analytics.signupFail;
            handleActionTracking(analyticsEvent);
          });
      }}
      variant={ButtonVariants.SSO}
      analyticsEvent={sendAnalytics ? analytics[actionType] : undefined}
    >
      {content}
    </Button>
  );
};
